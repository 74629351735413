import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Requested Page Not Found | The Ether Family Presents..." />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>That page has been lost.</h1>
          <p>We are experts at getting lost, so trust us. You won't find it. Go to the <a href="/">homepage</a> where there is great music to soothe your soul and guidance on how you might get lost yourself if you desire.</p>
          <h4>Or tell us where you are and what you want. We will try to help.</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
